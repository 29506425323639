<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="1000"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改调拨单' : '新建调拨单'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <H6 style="font-weight: 500">调拨方</H6>
    <a-divider style="border-color: black" dashed />
    <a-form
      ref="form"
      :model="customerForm"
      :rules="customerRules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      style="margin-top:20px;"
    >
      <a-row :gutter="24">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="调出方" name="out">
            <a-select
              v-model:value="customerForm.out"
              placeholder="请选择调出方"
            >
              <a-select-option value="0">厦门远丞科技有限公司</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="调入方" name="join">
            <a-select
              v-model:value="customerForm.join"
              placeholder="请选择调入方"
            >
              <a-select-option value="0">厦门远丞科技有限公司</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="调出站点" name="outPoint">
            <a-select
              v-model:value="customerForm.out"
              placeholder="请选择调出方"
            >
              <a-select-option value="0">厦门远丞科技有限公司</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="调入站点" name="joinPoint">
            <a-select
              v-model:value="customerForm.out"
              placeholder="请选择调出方"
            >
              <a-select-option value="0">厦门远丞科技有限公司</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="调拨原因" name="allotReason">
            <a-textarea v-model:value="customerForm.allotReason" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <H6 style="font-weight: 500">电池信息</H6>
    <a-divider style="border-color: black" dashed />
    <a-row type="flex" justify="end" style="margin:10px 0">
      <a-space style="margin: 0 20px">
        <a-button type="primary">
          <template #icon>
            <plus-outlined />
          </template>
          <span>批量新增</span>
        </a-button>
        <a-button type="primary">
         <template #icon>
            <plus-outlined />
          </template>
          <span>新建</span>
        </a-button>
      </a-space>
    </a-row>
    <a-table :data-source="dataSource" :columns="columns" row-key="batteryCode">
    </a-table>
  </a-modal>
</template>

<script>
// import * as batteryModelApi from '@/api/rnt/batteryModel'
// import * as operatorApi from '@/api/rnt/operator'
import { PlusOutlined } from '@ant-design/icons-vue'
export default {
  name: 'batteryModelEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    PlusOutlined
  },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          algin: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '电池编号',
          dataIndex: 'batteryCode',
          algin: 'center'
        },
        {
          title: '品牌',
          dataIndex: 'brand',
          algin: 'center'
        },
        {
          title: '型号',
          dataIndex: 'model',
          algin: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          algin: 'center'
        }
      ],
      dataSource: [
        {
          batteryCode: 'BT983298573487384593',
          brand: '顶宇锂电',
          model: '72v55Ah'
        }
      ],
      // 表单数据
      customerForm: Object.assign({}, this.data, { model: '' }),
      // 编辑弹窗表单验证规则
      customerRules: {
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
            type: 'string'
          }
        ],
        operator: [
          {
            required: true,
            message: '请选择所属运营商',
            trigger: 'blur',
            type: 'string'
          }
        ],
        realName: [
          {
            required: true,
            message: '请输入真实姓名',
            trigger: 'blur',
            type: 'string'
          }
        ],
        IDNumber: [
          {
            required: true,
            message: '请输入身份证号码',
            trigger: 'blur',
            type: 'string'
          }
        ],
        deposit: [
          {
            required: true,
            message: '押金不为空且最大6位数,保留小数点2位',
            trigger: 'blur',
            type: 'string'
          }
        ],
        balance: [
          {
            required: true,
            message: '请输入余额',
            trigger: 'blur',
            type: 'string'
          }
        ],
        giveBalance: [
          {
            required: true,
            message: '请输入赠送余额',
            trigger: 'blur',
            type: 'string'
          }
        ],
        point: [
          {
            required: true,
            message: '请选择所属租赁点',
            trigger: 'blur',
            type: 'string'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 项目列表
      modules: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      // 运营商名称
      operatorList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        console.log('data', this.data)
        this.customerForm = this.data
        this.isUpdate = true
      } else {
        this.customerForm = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    // this.queryOperatorList()
    // this.getBrandList()
    // this.getModelList()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          // this.loading = true
          // this.form.createUserId = this.$store.state.user.user.userId
          // batteryModelApi
          //   .save(this.form, this.isUpdate)
          //   .then((res) => {
          //     this.loading = false
          //     if (res.code === 0) {
          //       this.$message.success(res.msg)
          //       if (!this.isUpdate) {
          //         this.form = {}
          //       }
          //       this.updateVisible(false)
          //       this.$emit('done')
          //     } else {
          //       this.$message.error(res.msg)
          //     }
          //   })
          //   .catch((e) => {
          //     this.loading = false
          //     this.$message.error(e.message)
          //   })
        })
        .catch(() => {})
    },
    /* 查询运营商 */
    // queryOperatorList() {
    //   operatorApi
    //     .page()
    //     .then((res) => {
    //       if (res.code === 0) {
    //         this.operatorList = res.data
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
<style scoped lang='less'>
.inputNumber {
  width: 100%;
}
</style>
